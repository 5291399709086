import { useRouter } from 'next/router';
import tw from 'twin.macro';
import Logo from 'components/Logo';

const logoColor: CSS = {
	...tw`text-secondary`,
	'path:nth-child(3)': {
		...tw`text-primary`,
	},
};

export const logoText = tw`leading-4 font-semibold sm:(font-size[large] leading-5) `;

const zcmTranslations = {
	de: {
		top: 'Zentrum für',
		bottom: 'Cannabismedizin',
	},
	en: {
		top: 'Center for',
		bottom: 'Cannabis Medicine',
	},
	fr: {
		top: 'Centre de',
		bottom: 'Médecine du Cannabis',
	},
	es: {
		top: 'Centro de',
		bottom: 'Medicina del Cannabis',
	},
	it: {
		top: 'Centro per',
		bottom: 'Medicina della Cannabis',
	},
	pt: {
		top: 'Centro para',
		bottom: 'Medicina Canábis',
	},
	nl: {
		top: 'Centrum voor',
		bottom: 'Cannabis Medicijn',
	},
};
export const LogoWithText = () => {
	const { locale, defaultLocale } = useRouter();
	const translation =
		zcmTranslations[locale || defaultLocale || ''] || zcmTranslations['de'];
	return (
		<span tw="flex items-center">
			<Logo tw="h-9 w-auto sm:h-12" css={logoColor} />
			<div tw="ml-3 flex flex-col">
				<span tw="text-primary" css={logoText}>
					{translation.top}
				</span>
				<span tw="text-secondary" css={logoText}>
					{translation.bottom}
				</span>
			</div>
		</span>
	);
};

export const focusStyle = tw`focus:(outline-none ring-2 ring-offset-2 ring-primary)`;

export const mobileMenuButtonStyle = tw`rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:(text-gray-500 bg-gray-100) focus:(outline-none ring-2 ring-inset ring-primary)`;

export const roundedBadge = tw`inline-flex items-center px-3 py-0.5 rounded-full font-medium bg-brand-100 text-brand-800`;
