import { Popover } from '@headlessui/react';
import { Fragment } from 'react';
import tw from 'twin.macro';
import DatoLink from '@datocms/Link';
import type { LinkBlock } from '@datocms/types';
import Heroicon from 'components/Heroicon';
import Transition from 'components/Transition';
import { LogoWithText, mobileMenuButtonStyle } from './common';

const PrimaryLink = ({ heroicon, name, ...props }: LinkBlock) => {
	return (
		<DatoLink
			{...props}
			tw="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50">
			<Heroicon
				heroicon={heroicon ?? ''}
				tw="flex-shrink-0 h-6 w-6 text-primary"
				aria-hidden="true"
			/>
			<span tw="ml-3 text-base font-medium text-gray-900">{name}</span>
		</DatoLink>
	);
};

const SecondaryLink = ({ name, ...props }: LinkBlock) => {
	return (
		<DatoLink
			{...props}
			tw="break-words text-base font-medium text-gray-900 hover:text-gray-700">
			{name}
		</DatoLink>
	);
};

const MobileNavigationMenu = ({ open, primaryLinks, secondaryLinks, ctas }) => {
	return (
		<Transition
			show={open}
			as={Fragment}
			enter={tw`duration-200 ease-out`}
			enterFrom={tw`opacity-0 scale-95`}
			enterTo={tw`opacity-100 scale-100`}
			leave={tw`duration-100 ease-in`}
			leaveFrom={tw`opacity-100 scale-100`}
			leaveTo={tw`opacity-0 scale-95`}>
			<Popover.Panel
				focus
				static
				tw="absolute top-0 inset-x-0 p-2 transition transform origin-top-right lg:hidden">
				<div tw="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
					<div tw="pt-5 pb-6 px-5">
						<div tw="flex items-center justify-between">
							<LogoWithText />
							<div tw="-mr-2">
								<Popover.Button css={mobileMenuButtonStyle}>
									<span tw="sr-only">Close menu</span>
									<Heroicon
										heroicon="x"
										tw="h-6 w-6 sm:(h-8 w-8)"
										aria-hidden="true"
									/>
								</Popover.Button>
							</div>
						</div>
						<div tw="mt-6">
							<nav tw="grid gap-y-8">
								{primaryLinks.map((item) => (
									// eslint-disable-next-line react/jsx-key
									<PrimaryLink key={item.id} {...item} />
								))}
							</nav>
						</div>
					</div>
					{secondaryLinks.length > 0 && (
						<div tw="py-6 px-5 space-y-6">
							<div tw="grid grid-cols-2 gap-y-4 gap-x-8">
								{secondaryLinks.map((item) => (
									<SecondaryLink key={item.id} {...item} />
								))}
							</div>
						</div>
					)}
				</div>
			</Popover.Panel>
		</Transition>
	);
};

export default MobileNavigationMenu;
