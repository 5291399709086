import { useRouter } from 'next/router';
import { uniqBy } from 'ramda';

const usePageContext = (data: any) => {
	const { defaultLocale, isPreview, ...routingProps } = useRouter();
	const preview = isPreview || process.env.NODE_ENV === 'development';
	const page = data?.page;
	const index = data?.index;
	const items = data?.items;
	const layout = data?.layout || page?.layout || index?.layout;

	const _modelApiKey = page?._modelApiKey;
	let availableLocales = [] as any[];
	if (_modelApiKey === 'journal_article') {
		availableLocales =
			page._allSlugLocales.map(({ locale, value }) => [
				locale,
				`journal/${value}`,
			]) ?? [];
	} else if (_modelApiKey === 'journal_index') {
		const collectedSlugLocales = items.flatMap(
			(article) => article._allSlugLocales
		);
		availableLocales = uniqBy<any, any>(
			({ locale }) => locale,
			collectedSlugLocales
		).map(({ locale }) => [locale, `journal`]);
	} else if (['home_page', 'composable_page'].includes(_modelApiKey)) {
		availableLocales =
			page._allSlugLocales.map(({ locale, value }) => [locale, value]) ?? [];
	}

	return {
		_modelApiKey,
		isPreview: preview,
		layout,
		availableLocales,
		page,
		index,
		defaultLocale,
		...routingProps,
	};
};

export default usePageContext;
