import type { SiteOwner } from '@datocms/types';
import Footer from '@layouts/footers/SimpleCentered';
import Header from '@layouts/navigation/CenteredWithBottomBorder';
import 'twin.macro';

type LayoutProps = {
	availableLocales: string[];
	layout: {
		siteOwner: SiteOwner;
		desktopNavigationMenu: Record<string, any>;
		mobileNavigationMenu: Record<string, any>;
		footer: Record<string, any>;
	};
};
const Layout = ({
	children,
	availableLocales,
	layout,
}: React.PropsWithChildren<LayoutProps>) => {
	const { desktopNavigationMenu, mobileNavigationMenu, siteOwner, footer } =
		layout;
	return (
		<div tw="flex flex-col min-h-screen">
			<Header
				id="header-navigation"
				data-datocms-noindex
				availableLocales={availableLocales}
				desktopNavigationMenu={desktopNavigationMenu}
				mobileNavigationMenu={mobileNavigationMenu}
			/>
			<main id="main-content" tw="overflow-x-hidden">
				{children}
			</main>
			<Footer
				id="footer-navigation"
				data-datocms-noindex
				tw="flex-auto"
				siteOwner={siteOwner}
				links={footer.section?.[0]?.links ?? []}
			/>
		</div>
	);
};

export default Layout;
