/* This example requires Tailwind CSS v2.0+ */
import { useRouter } from 'next/router';
import 'twin.macro';
import Heroicon from 'components/Heroicon';
import SmartLink from 'components/SmartLink';
import { languageNameTranslations } from 'i18n';
import { mobileMenuButtonStyle } from './common';

const formUrl = process.env.NEXT_PUBLIC_GOOGLE_FORMS_FEEDBACK_URL ?? '';
const prefillLanguageId =
	process.env.NEXT_PUBLIC_GOOGLE_FORMS_FEEDBACK_PREFILL_LANGUAGE_ID ?? '';
const prefillUrlId =
	process.env.NEXT_PUBLIC_GOOGLE_FORMS_FEEDBACK_PREFILL_URL_ID ?? '';

const useGoogleFormsFeedbackLink = (
	languageNameMap = languageNameTranslations
) => {
	const siteUrl = process.env.NEXT_PUBLIC_SITE_URL || '';
	const { locale, asPath, defaultLocale } = useRouter();
	const prefillLanguage = `&entry.${prefillLanguageId}=${
		languageNameMap[locale || '']
	}`;
	const prefillUrl = `&entry.${prefillUrlId}=${siteUrl}${
		locale !== defaultLocale ? `/${locale}` : ''
	}${asPath}`;
	return `${formUrl}/viewform?usp=pp_url${prefillLanguage}${prefillUrl}`;
};

export default function FeedbackButton() {
	const feedbackLink = useGoogleFormsFeedbackLink();

	return (
		<SmartLink css={mobileMenuButtonStyle} href={feedbackLink}>
			<span tw="sr-only">Send us feedback</span>
			<Heroicon
				heroicon="chat-alt"
				tw="h-6 w-6 sm:(h-8 w-8)"
				aria-hidden="true"
			/>
		</SmartLink>
	);
}
