import dynamic from 'next/dynamic';

// noop dynamic import for tracking in next.lock
async () =>
	await import(
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		'https://unpkg.com/datocms-search@0.1.8/dist/datocms-search.widget.js'
	);

const importModules = async () => {
	const searchModule = await import(
		'next.lock/data/https_unpkg.com/datocms-search_0.1.8_dist_datocms-search.widget_c91e781a08a9b7bec46c'
	);
	const widgetModule = await import('./SiteSearchWidget');
	return [searchModule, widgetModule] as [any, typeof widgetModule];
};

const importWidgetWithInjectedSearch = () =>
	importModules().then(([searchModule, widgetModule]) => {
		const Widget = widgetModule.default;
		// eslint-disable-next-line sonarjs/prefer-immediate-return
		const WidgetWithInjectedSearch = (
			props: Omit<React.ComponentProps<typeof Widget>, 'DatoCmsSearch'>
		) => <Widget {...props} DatoCmsSearch={searchModule.default} />;
		return WidgetWithInjectedSearch;
	});

const DynamicSiteSearchWidget = dynamic(importWidgetWithInjectedSearch, {
	ssr: false,
});

export default DynamicSiteSearchWidget;
