import type { TransitionEvents } from '@headlessui/react';
import { Transition as HeadlessUiTransition } from '@headlessui/react';
import type { TwStyle } from 'twin.macro';
import { inlineCss } from 'stitches.config';

/**
 * HeadlessUI "Transition"
 * Customized for twin.macro + typescript
 * https://headlessui.dev/react/transition
 */

type TransitionProps = {
	enter?: TwStyle;
	enterFrom?: TwStyle;
	enterTo?: TwStyle;
	entered?: TwStyle;
	leave?: TwStyle;
	leaveFrom?: TwStyle;
	leaveTo?: TwStyle;
	children: React.ReactNode;
	show?: boolean;
	as?: React.ElementType;
	unmount?: boolean;
} & TransitionEvents;

export default function Transition(props: TransitionProps) {
	return <HeadlessUiTransition {...getProps(props)} />;
}

Transition.Child = function TransitionChild(props: TransitionProps) {
	return <HeadlessUiTransition.Child {...getProps(props)} />;
};

function getProps(props: TransitionProps) {
	const {
		enter,
		enterFrom,
		enterTo,
		entered,
		leave,
		leaveFrom,
		leaveTo,
		...rest
	} = props;
	return {
		...rest,
		...(enter ? { enter: inlineCss(enter).className } : {}),
		...(enterFrom ? { enterFrom: inlineCss(enterFrom).className } : {}),
		...(enterTo ? { enterTo: inlineCss(enterTo).className } : {}),
		...(entered ? { entered: inlineCss(entered).className } : {}),
		...(leave ? { leave: inlineCss(leave).className } : {}),
		...(leaveFrom ? { leaveFrom: inlineCss(leaveFrom).className } : {}),
		...(leaveTo ? { leaveTo: inlineCss(leaveTo).className } : {}),
	};
}
