export const id = 'adept-knitter-897';

export const forms = {
	journal: {
		de: '4f18004d6b',
	},
	news: {
		de: '7cbb16d150',
	},
};
