import { default as FacebookIcon } from './Facebook';
import { default as InstagramIcon } from './Instagram';
import { default as LinkedInIcon } from './LinkedIn';

export { FacebookIcon, InstagramIcon, LinkedInIcon };

export const socialIconsMap = {
	facebook: FacebookIcon,
	instagram: InstagramIcon,
	linkedin: LinkedInIcon,
};
