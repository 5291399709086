import NextLink from 'next/link';
import { pipe } from 'ramda';

export interface SmartLinkProps extends React.ComponentProps<'a'> {
	locale?: string | false;
	tabMode?: 'new_tab' | 'same_tab';
}

const SITE_URL = process.env.NEXT_PUBLIC_SITE_URL || 'http://localhost:3000';

const withAbsoluteUrlStripped = (href: string) =>
	href.startsWith(SITE_URL) ? href.replace(SITE_URL, '') || '/' : href;

const withHttpsIfStartsWithWww = (href: string) =>
	href.startsWith('www.') ? href.replace('www.', 'https://www.') : href;

const SmartLink: React.FC<SmartLinkProps> = ({
	children,
	key,
	href = '/',
	tabMode,
	locale,
	className,
	target,
	css,
	...aProps
}) => {
	const sanitizedHref = pipe(
		withAbsoluteUrlStripped,
		withHttpsIfStartsWithWww
	)(href);

	const isRelativePath = sanitizedHref.startsWith('/');
	const isFragment = sanitizedHref.startsWith('#') || href.startsWith('/#');
	const isSameSite = isFragment || isRelativePath;
	const isExternal = !isSameSite;

	const isTargetBlank = target === '_blank';
	const activeTabMode =
		tabMode !== undefined
			? tabMode
			: isTargetBlank || isExternal
			? 'new_tab'
			: 'same_tab';

	const openInNewTab = !isFragment && activeTabMode === 'new_tab';

	return (
		<NextLink
			key={key}
			locale={locale}
			href={sanitizedHref}
			passHref={openInNewTab || isExternal}>
			<a
				css={css}
				{...aProps}
				className={className}
				target={openInNewTab ? '_blank' : undefined}
				// Add noopener and noreferrer for security reasons
				rel={openInNewTab ? 'noopener noreferrer' : undefined}>
				{children}
			</a>
		</NextLink>
	);
};

export default SmartLink;
