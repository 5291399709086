// from https://stackoverflow.com/questions/4068573/convert-string-to-pascal-case-aka-uppercamelcase-in-javascript
export function toPascalCase(string) {
	return `${string}`
		.replace(/[-_]+/g, ' ')
		.replace(/[^\w\s]/g, '')
		.replace(
			/\s+(.)(\w*)/g,
			($1, $2, $3) => `${$2.toUpperCase() + $3.toLowerCase()}`
		)
		.replace(/\w/, (s) => s.toUpperCase());
}
