import type { LinkBlock } from '@datocms/types';
import type { SmartLinkProps } from 'components/SmartLink';
import Link from 'components/SmartLink';

type DatoLinkInput = Omit<LinkBlock, 'id' | 'name'>;
type DatoLinkOutput = Pick<SmartLinkProps, 'href' | 'tabMode'>;

const getSmartlinkProps = ({
	fragment,
	page,
	openInNewTab,
	linkMode,
	href,
}: // eslint-disable-next-line sonarjs/cognitive-complexity
DatoLinkInput): DatoLinkOutput => {
	const tabMode = openInNewTab ? 'new_tab' : 'same_tab';

	let effectiveHref = '/';
	if (linkMode === 'page' && page) {
		const appendfragment = fragment ?? '';
		if (page.__typename === 'HomePageRecord') {
			effectiveHref = `/${appendfragment}`;
		}

		if (page.__typename === 'ComposablePageRecord') {
			effectiveHref = `/${page.slug}${appendfragment}`;
		}

		if (page.__typename === 'JournalArticleRecord') {
			effectiveHref = `/journal/${page.slug}${appendfragment}`;
		}

		if (page.__typename === 'JournalIndexRecord') {
			effectiveHref = `/journal/${appendfragment}`;
		}
	}
	if (linkMode === 'href' && href) {
		effectiveHref = href;
	}

	return {
		href: effectiveHref,
		tabMode,
	};
};

export type DatoLinkProps = Omit<SmartLinkProps, keyof DatoLinkOutput> &
	DatoLinkInput;

const DatoLink: React.FC<DatoLinkProps> = ({
	fragment,
	page,
	openInNewTab,
	linkMode,
	href,
	heroicon,
	description,
	...props
}) => {
	const datoSmartLinkProps = getSmartlinkProps({
		fragment,
		page,
		openInNewTab,
		linkMode,
		href,
	});
	return !page && fragment ? (
		// eslint-disable-next-line jsx-a11y/anchor-has-content
		<a href={fragment} {...props} />
	) : (
		<Link {...datoSmartLinkProps} {...props} />
	);
};

export default DatoLink;
