/* eslint-disable @typescript-eslint/ban-ts-comment */
import debounceFn from 'debounce-fn';
import { atom } from 'jotai';

// https://github.com/KyleAMathews/react-headroom/blob/master/src/supportsPassiveEvents.js
const supportsPassiveEvents = () => {
	let passiveSupported = false;

	try {
		const options = {
			get passive() {
				// This function will be called when the browser
				// attempts to access the passive property.
				passiveSupported = true;
				return false;
			},
		};
		// @ts-ignore
		window.addEventListener('test', null, options);
		// @ts-ignore
		window.removeEventListener('test', null, options);
	} catch (err) {
		passiveSupported = false;
	}

	return passiveSupported;
};

type DebounceFnOptions = NonNullable<Parameters<typeof debounceFn>[1]>;
type Cfg = {
	upTolerance?: number;
	downTolerance?: number;
	passiveOnly?: boolean;
} & DebounceFnOptions;

export const windowScrollYAtom = ({
	upTolerance,
	downTolerance,
	passiveOnly,
	...debounceCfg
}: Cfg) => {
	type ScrollPosition = {
		direction?: 'up' | 'down';
		y: number;
		distance: number;
	};
	const dataAtom = atom<ScrollPosition>({
		y: 0,
		distance: 0,
	});

	let lastY = 0;
	// eslint-disable-next-line sonarjs/cognitive-complexity
	dataAtom.onMount = (set) => {
		const passiveSupport = supportsPassiveEvents();
		if (!passiveSupport && passiveOnly) {
			return;
		}
		const handler = () => {
			const y = window.scrollY;
			const distance = Math.abs(y - lastY);
			const direction = y > lastY ? 'down' : 'up';
			if (
				(direction === 'up' && distance > (upTolerance || 0)) ||
				(direction === 'down' && distance > (downTolerance || 0))
			) {
				set({ y, direction, distance });
				lastY = y;
			}
		};
		const wait = debounceCfg?.wait || 0;
		const debouncedHandler = wait > 0 && debounceFn(handler, debounceCfg);
		const listener = debouncedHandler || handler;
		const options = {
			...(passiveSupport ? { passive: true } : {}),
		};
		window.addEventListener('scroll', listener, options);
		return () => {
			if (debouncedHandler) {
				debouncedHandler.cancel();
			}
			window.removeEventListener('scroll', listener);
		};
	};
	return dataAtom;
};

export const isScrollingAtom = (
	stopDetectionTimeout = 250,
	cfg?: Omit<Cfg, 'upTolerance' | 'downTolerance'>
) => {
	const scrollingAtom = atom(false);
	const { passiveOnly, ...debounceCfg } = cfg ?? {};
	scrollingAtom.onMount = (set) => {
		const passiveSupport = supportsPassiveEvents();
		if (!passiveSupport && passiveOnly) {
			return;
		}
		const handleIsScrollingTimeout = () => {
			set(false);
		};

		let timer = setTimeout(handleIsScrollingTimeout, stopDetectionTimeout);
		const handler = () => {
			clearTimeout(timer);
			set(true);
			timer = setTimeout(handleIsScrollingTimeout, stopDetectionTimeout);
		};

		const wait = debounceCfg?.wait || 0;
		const debouncedHandler = wait > 0 && debounceFn(handler, debounceCfg);
		const listener = debouncedHandler || handler;
		const options = {
			...(passiveSupport ? { passive: true } : {}),
		};
		window.addEventListener('scroll', listener, options);
		return () => {
			window.removeEventListener('scroll', listener);
		};
	};

	return scrollingAtom;
};
