import Link from '@styled/LinkButton';
import { id as convertKitId } from 'constants/convertkit';

const FormLink = ({
	formId,
	children,
	className = '',
	toggle = true,
	heroicon = 'rss',
}) => {
	return (
		<Link
			{...(toggle ? { 'data-formkit-toggle': formId } : {})}
			heroicon={heroicon}
			className={className}
			href={`https://${convertKitId}.ck.page/${formId}`}>
			{children}
		</Link>
	);
};

export default FormLink;
