// https://tailwindui.com/components/marketing/sections/footers#component-8fd8a490ec2fae888154eb83685bbe23
import { useRouter } from 'next/router';
import { ascend, sortWith } from 'ramda';
import { Fragment, useCallback, useMemo } from 'react';
import tw, { styled } from 'twin.macro';
import DynamicDatoSearch from '@datocms/DynamicSiteSearchWidget';
import DatoLink from '@datocms/Link';
import type { LinkBlock, SiteOwner, StreetAddress } from '@datocms/types';
import * as socialIcons from '@icons/social';
import { linkFocusRing } from '@styled/Link';
import ConvertKitFormLink from 'components/convertkit/FormLink';
// import ConvertKitFormScript from 'components/convertkit/FormScript';

import Heroicon from 'components/Heroicon';
import Link from 'components/SmartLink';
import { forms } from 'constants/convertkit';
import { outerContainer } from 'styles';

type SimpleCenteredFooterProps = {
	siteOwner: SiteOwner;
	links: LinkBlock[];
} & React.ComponentProps<'footer'>;

const socialIconsMap = {
	facebook: socialIcons.FacebookIcon,
	instagram: socialIcons.InstagramIcon,
	linkedin: socialIcons.LinkedInIcon,
};

const socialSortOrder = {
	twitter: 0,
	instagram: 1,
	facebook: 2,
	linkedin: 3,
};

const socialSorter = sortWith([
	ascend<[string, string]>(([key]) => socialSortOrder[key]),
]);

const formatStreetAddress = ({ addressLine1, city, zipCode }: StreetAddress) =>
	`${addressLine1}, ${zipCode} ${city} `;

const searchAltLocales = {
	de: ['en'],
	es: ['en'],
	fr: ['en'],
	it: ['en'],
	nl: ['en'],
	pt: ['en'],
};
const SimpleCenteredFooter = ({
	siteOwner,
	links,
	...footerProps
}: SimpleCenteredFooterProps) => {
	const companyName = siteOwner.name;
	const physicalLocation = siteOwner.physicalLocation?.[0];
	const what3Words = physicalLocation?.what3words;
	const streetAddress = physicalLocation?.streetAddress?.[0];
	const currentYear = useMemo(() => new Date().getFullYear(), []);
	const socialEntries = socialSorter(
		Object.entries(
			siteOwner.virtualLocation?.[0]?.socialMedia?.[0] ?? {}
		).filter(([, url]) => !!url)
	);
	const email = siteOwner.virtualLocation?.[0]?.email;
	const { locale = 'de', locales } = useRouter();
	const subscribeFormId = forms.news[locale];

	const SearchWidget = useCallback(
		() => (
			<>
				{locales?.map(
					(l) =>
						l === locale && (
							<DynamicDatoSearch
								key={l}
								tw="flex justify-center"
								locale={locale}
								locales={searchAltLocales[locale]}
							/>
						)
				)}
			</>
		),
		[locale, locales]
	);
	return (
		<>
			<footer tw="bg-brand-50 border-t border-gray-200" {...footerProps}>
				<div
					css={outerContainer}
					tw="py-12 px-4 overflow-hidden space-y-8 sm:px-6 lg:px-8">
					<nav
						tw="-mx-5 -my-2 flex flex-wrap justify-center"
						aria-label="Footer">
						{links.map(({ name, id, ...linkProps }) => (
							<div key={id} tw="px-5 py-2">
								<span tw="sr-only">
									'Our location in Steinheim as 3 word address
								</span>
								<DatoLink
									{...linkProps}
									tw="text-lg font-bold text-primary hover:text-brand-500"
									css={linkFocusRing}>
									{name}
								</DatoLink>
							</div>
						))}
					</nav>
					{subscribeFormId && (
						<div tw="flex justify-center">
							<ConvertKitFormLink formId={subscribeFormId}>
								Newsletter
							</ConvertKitFormLink>
						</div>
					)}
					{(socialEntries.length > 0 || what3Words || email) && (
						<div tw="flex flex-col items-center justify-center space-y-6">
							{what3Words && (
								<Link
									href={`https://what3words.com/${what3Words.slice(3)}`}
									tw="text-primary hover:text-brand-500"
									css={linkFocusRing}>
									<span tw="inline-flex">
										<Heroicon
											tw="mr-1 h-6 w-6"
											css={linkFocusRing}
											type="solid"
											heroicon={'location-marker'}
											aria-hidden="true"
										/>
										<strong tw="truncate">
											{streetAddress
												? formatStreetAddress(streetAddress)
												: what3Words}
										</strong>
									</span>
								</Link>
							)}
							<div tw="flex space-x-6">
								{email && (
									<Link
										href={`mailto:${email}`}
										tw=" text-primary hover:text-brand-500"
										css={linkFocusRing}>
										<span tw="sr-only">Send us an email</span>
										<span tw="inline-flex">
											<Heroicon
												heroicon="mail"
												tw="mr-1 h-6 w-6"
												aria-hidden="true"
											/>
											{socialEntries.length === 0 && (
												<strong tw="truncate">{` ${email}`}</strong>
											)}
										</span>
									</Link>
								)}
								{socialEntries.map(([kind, url]) => {
									if (!url) {
										return <></>;
									}
									const Icon = socialIconsMap[kind];
									const StyledIcon = Icon
										? styled(Icon, tw`h-6 w-6`)
										: Fragment;
									return (
										<Link
											key={kind}
											href={url}
											tw="text-primary hover:text-brand-500"
											css={linkFocusRing}>
											<span tw="sr-only">{kind}</span>
											<StyledIcon aria-hidden />
										</Link>
									);
								})}
							</div>
						</div>
					)}
					<p tw="text-center text-base text-primary">
						&copy; {`${currentYear}`}{' '}
						{companyName || 'Zentrum für Cannabismedizin'}
					</p>
				</div>
				<div
					id="datocms-site-search-footer"
					css={outerContainer}
					tw="min-h-screen px-4 sm:px-6 lg:px-8 pb-6">
					<SearchWidget />
				</div>
			</footer>

			{/* {subscribeFormId && <ConvertKitFormScript formId={subscribeFormId} />} */}
		</>
	);
};

export default SimpleCenteredFooter;
