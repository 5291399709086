import * as OutlineIcons from '@heroicons/react/outline';
import * as SolidIcons from '@heroicons/react/solid';
import React from 'react';
import { toPascalCase } from 'utils/string';

type HeroiconProps = {
	heroicon: string;
	type?: 'outline' | 'solid';
};
const outlineCcons = OutlineIcons;
const solidIcons = SolidIcons;

const Heroicon: React.FC<React.ComponentProps<'svg'> & HeroiconProps> = ({
	heroicon,
	type = 'outline',
	className,
}) => {
	const Icon = React.useMemo(() => {
		const icons = type === 'outline' ? outlineCcons : solidIcons;
		return icons?.[`${toPascalCase(heroicon)}Icon`] ?? React.Fragment;
	}, [heroicon, type]);
	return <Icon className={className} />;
};

export default Heroicon;
