import tw, { styled } from 'twin.macro';
import Heroicon from 'components/Heroicon';
import type { SmartLinkProps } from 'components/SmartLink';
import Link from 'components/SmartLink';

export const linkFocusRing = tw`focus:(rounded-sm outline-none ring-2 ring-offset-1 ring-primary)`;

export const InlineLink = styled(Link, {
	...tw`text-primary! hover:text-secondary! visited:text-secondary!`,
	...linkFocusRing,
	...tw`-sm:(break-words!) no-underline!`,
});

export const ButtonLink = ({
	heroicon,
	children,
	...linkProps
}: SmartLinkProps & { heroicon?: string }) => {
	return (
		<Link
			tw="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm lg:text-base font-medium rounded-md text-white bg-primary hover:bg-brand-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500"
			{...linkProps}>
			{heroicon && (
				<Heroicon
					heroicon={heroicon}
					tw="-ml-1 mr-2 lg:mr-3 h-5 w-5"
					aria-hidden="true"
				/>
			)}
			{children}
		</Link>
	);
};

export default InlineLink;
