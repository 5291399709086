import 'twin.macro';

const Logo = (props: React.ComponentProps<'svg'>) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24.697951 24.000027"
		fill="currentColor"
		stroke="none"
		{...props}>
		<path d="m4.8992 7.5076-4.8992 4.8995 6.1744 6.1744 1.816-1.7902c0.71272-0.7026 0.71701-1.8515 0.0092-2.5593l-1.8249-1.8249 3.0872-3.0872-1.812-1.812c-0.70474-0.70444-1.8463-0.70444-2.5508-3e-4z" />
		<path d="m18.524 6.2324 6.1744 6.1747-11.065 11.065c-0.70781 0.70781-1.8564 0.70382-2.5593-9e-3l-1.7902-1.816 9.2399-9.2396-1.7865-1.9475c-0.65294-0.7118-0.62903-1.8114 0.05364-2.494z" />
		<path d="m12.291 0 1.8699 1.8699c0.70413 0.70413 0.70413 1.846 0 2.5501l-1.812 1.812-1.8374-1.8374c-0.69524-0.69524-0.70536-1.8196-0.02268-2.5272z" />
	</svg>
);

export default Logo;
