import { isLink } from 'datocms-structured-text-utils';
import { renderRule } from 'react-datocms';
import A from '@styled/Link';

export const linkRule = renderRule(isLink, ({ node, key, children }) => {
	const openInNewTab = !!node.meta?.find(
		(x) => x.id === 'target' && x.value === '_blank'
	);
	return (
		<A
			key={key}
			tabMode={openInNewTab ? 'new_tab' : 'same_tab'}
			href={node.url}>
			{children}
		</A>
	);
});

const globalRules = [linkRule];
export default globalRules;
