import type { AppProps } from 'next/app';
import Head from 'next/head';
import Script from 'next/script';
import Layout from '@layouts/index';
import usePageContext from 'hooks/usePageContext';
import usePathWithLocale from 'hooks/usePathWithLocale';
import useShiftFocusOnNavigation from 'hooks/useShiftFocusOnNavigation';
import globalStyles from 'styles/globalStyles';
import useFathomAnalytics from 'hooks/useFathomAnalytics';

const userCentricsId = process.env.NEXT_PUBLIC_USERCENTRICS_ID;

const UserCentricsConsent = () => (
	<>
		<Head>
			<link rel="preconnect" href="https://app.usercentrics.eu" />
			<link rel="preconnect" href="https://privacy-proxy.usercentrics.eu" />
		</Head>
		<Script
			id={userCentricsId}
			strategy="beforeInteractive"
			src="https://app.usercentrics.eu/latest/main.js"
		/>
		<Script
			strategy="afterInteractive"
			src="https://privacy-proxy.usercentrics.eu/latest/uc-block.bundle.js"
		/>
	</>
);

function MyApp({ Component, pageProps }: AppProps) {
	useFathomAnalytics();
	useShiftFocusOnNavigation();
	const path = usePathWithLocale();
	const canonicalUrl = `${process.env.NEXT_PUBLIC_SITE_URL}${
		path !== '/' ? path : ''
	}`;

	const data = pageProps?.querySubscriptionData?.initialData;
	const { availableLocales, layout, isPreview } = usePageContext(data);

	globalStyles();
	return (
		<>
			<Head>
				<link rel="canonical" href={canonicalUrl} />
			</Head>
			{/* <UserCentricsConsent /> */}
			{!isPreview && layout ? (
				<Layout availableLocales={availableLocales} layout={layout}>
					<Component {...pageProps} />
				</Layout>
			) : (
				<Component {...pageProps} />
			)}
		</>
	);
}

export default MyApp;
