export const defaultRegions = {
	de: 'DE',
	en: 'US',
	fr: 'FR',
	it: 'IT',
	es: 'ES',
	pt: 'PT',
	nl: 'NL',
};

export const languageNameTranslations = {
	en: 'English',
	de: 'Deutsch',
	es: 'Español',
	fr: 'Français',
	it: 'Italiano',
	nl: 'Nederlands',
	pt: 'Português',
};

export const readingTimeTranslations = {
	en: 'reading time',
	fr: 'temps de lecture',
	de: 'Lesezeit',
	es: 'tiempo de lectura',
	it: 'tempo di lettura',
	pt: 'tempo de leitura',
	nl: 'lees tijd',
};

export const readMoreTranslations = {
	en: 'Read more →',
	de: 'Mehr lesen →',
	fr: 'Lire la suite →',
	it: 'Leggi di più →',
	es: 'Leer más →',
	pt: 'Leia mais →',
	nl: 'Lees meer →',
};

export const publishedTranslations = {
	en: 'Published',
	de: 'Veröffentlicht',
	fr: 'Publié',
	it: 'Pubblicato',
	es: 'Publicado',
	pt: 'Publicado',
	nl: 'Gepubliceerd',
};

export const lastUpdatedTranslations = {
	de: 'Zuletzt aktualisiert',
	en: 'Last updated',
	fr: 'Dernière actualisation',
	it: 'Ultimo aggiornamento',
	es: 'Última actualización',
	pt: 'Última actualização',
	nl: 'Laatst bijgewerkt',
};

export const publisherTranslations = {
	de: 'Herausgeber',
	en: 'Publisher',
	fr: 'Éditeur',
	it: 'Editore',
	es: 'Editor',
	pt: 'Editor',
	nl: 'Uitgever',
};

export type FormatStyleOptions = Intl.DateTimeFormatOptions;

export function formatDateTimeString(
	dateTimeString: string,
	locale = 'de-DE',
	style?: Intl.DateTimeFormatOptions
): string {
	if (!Intl.DateTimeFormat) {
		return dateTimeString;
	}
	const isLanguage = locale.length === 2;
	const effectiveLocale = isLanguage
		? `${locale}-${defaultRegions[locale]}`
		: locale;
	return Intl.DateTimeFormat(effectiveLocale, style).format(
		new Date(dateTimeString)
	);
}

export function formatNumber(
	number: number,
	locale = 'de-DE',
	style?: Intl.NumberFormatOptions
): number | string {
	if (!Intl.NumberFormat) {
		return number;
	}
	const isLanguage = locale.length === 2;
	const effectiveLocale = isLanguage
		? `${locale}-${defaultRegions[locale]}`
		: locale;
	return Intl.NumberFormat(effectiveLocale, style).format(number);
}
