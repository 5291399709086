import tw, { theme, globalStyles } from 'twin.macro';
import { globalCss } from 'stitches.config';
import { purpleHazeTextGradient } from 'styles';

const customStyles = globalCss({
	body: {
		...tw`antialiased`,
		WebkitTapHighlightColor: theme`colors.secondary`,
		mark: tw`text-gray-700 font-medium bg-brand-200/80`,
		'a > mark': {
			...tw`font-bold`,
			...purpleHazeTextGradient,
		},
	},
});

const styles = () => {
	customStyles();
	globalCss(globalStyles as any)();
};

export default styles;
