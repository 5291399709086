import type { LoadOptions } from 'fathom-client';
import { load, trackPageview } from 'fathom-client';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

const trackPageViewWithUrl = (url: string) => trackPageview({ url });

const nodeEnv = process.env.NODE_ENV;
const siteId = process.env.NEXT_PUBLIC_FATHOM_SITE_ID;
const includedDomains = process.env.NEXT_PUBLIC_FATHOM_INCLUDED_DOMAINS;
const excludedDomains = process.env.NEXT_PUBLIC_FATHOM_EXCLUDED_DOMAINS;

/**
 * React hook for Fathom Analytics router event tracking
 * from https://www.joshmedeski.com/posts/how-to-install-fathom-analytics-in-nextjs
 */
const useFathomAnalytics = () => {
	const { events } = useRouter();

	useEffect(() => {
		if (nodeEnv !== 'production' || !siteId) return;
		const loadOptions: LoadOptions = {};

		if (includedDomains) {
			loadOptions.includedDomains = includedDomains.split(',');
		}

		if (excludedDomains) {
			loadOptions.excludedDomains = excludedDomains.split(',');
		}

		load(siteId, loadOptions);
		events.on('routeChangeComplete', trackPageViewWithUrl);

		return () => events.off('routeChangeComplete', trackPageViewWithUrl);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
};

export default useFathomAnalytics;
